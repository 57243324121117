/* eslint-disable max-len */
import style from './LandingModalContent.module.scss';
import PropTypes from 'prop-types';

export const LandingModalContent = ({ content }) => (
  <div className={style.modalText}>
    {content === 'AMLPolicy' && (
      <>
        <h1 className={style.heading}>Политика KYC/AML</h1>
        <p className={style.text}>Провайдер использует независимые сторонние компании в следующих целях:</p>
        <p className={style.text}>
          Политика по предотвращению легализации доходов, добытых преступным путем, финансирования терроризма и проверки
          благонадежности клиентов (далее – «Политика») предназначена для предотвращения и снижения возможных рисков
          Провайдера быть вовлеченным в какую-либо незаконную деятельность.
        </p>
        <p className={style.text}>
          Для соответствия международными и местными нормативными актами Провайдер внедряет эффективные внутренние
          процедуры и механизмы по предотвращению отмывания денег, финансирования терроризма, торговли наркотиками и
          людьми, распространения оружия массового уничтожения, коррупции и взяточничества и реагированию в случае
          какой-либо формы подозрительной деятельности со стороны своих Пользователей.
        </p>
        <p className={style.text}>
          Под легализацией доходов, добытых преступным путем, следует понимать перевод или передачу имущества,
          полученное от преступной деятельности или от участия в такой деятельности, в целях сокрытия или маскирования
          незаконного происхождения имущества или оказания помощи любому лицу, причастному к совершению такой
          деятельности с целью уклонения от правовых последствий его действий.
        </p>
        <p className={style.text}>
          Под финансированием терроризма следует понимать умышленное предоставление или сбор любыми средствами, прямо
          или косвенно, средств с намерением использовать эти средства или при условии, что они будут использоваться для
          совершения террористических актов.
        </p>
        <p className={style.text}>
          Для борьбы с отмыванием денег (AML) по всему миру принимается все больше и больше обременительных законов и
          постановлений. Хотя законы и правила AML различаются в зависимости от страны и региона, у них часто есть общие
          черты, включая необходимость разработки политик и процедур, а также инвестирования в технологии для борьбы со
          всеми формами финансовых преступлений.
        </p>
        <p className={style.text}>
          Чтобы помочь бороться с финансированием терроризма и отмыванием денег, Провайдер требует от Пользователей
          предоставить определенную информацию, указанную в Соглашении, которая позволяет идентифицировать Пользователя
          до совершения операций. Провайдер также вправе попросить Пользователя предоставить определенные документы,
          удостоверяющие личность, с целью проверки личности и определения соответствия критериям для осуществления
          операций. Без предоставления данной информации и документов осуществление операций невозможно.
        </p>
        <p className={style.text}>
          Подавая заявку на открытие Личного кабинета, Пользователь соглашается предоставить информацию и документацию,
          запрошенную Провайдером, и соглашается на получение Провайдером дополнительной информации от третьих лиц.
        </p>
        <p className={style.text}>
          Процедура надлежащей проверки Пользователей требует, чтобы Пользователи предоставляли Провайдеру достоверные
          документы для их идентификации. Такие документы могут включать в себя, например, паспорт, выписку из банка,
          счет за коммунальные услуги. Для этих целей Провайдер оставляет за собой право собирать идентификационную
          информацию Пользователей для целей соблюдения Политики.
        </p>
        <p className={style.text}>
          Провайдер предпримет шаги для подтверждения подлинности документов и информации, предоставленной
          Пользователями. Провайдер также будет проверять идентификационную информацию с помощью вторичных источников.
        </p>
        <p className={style.text}>
          Провайдер оставляет за собой право проводить мониторинг данных Пользователя на постоянной основе, особенно,
          когда его идентификационная информация была изменена или его деятельность показалась подозрительной или
          необычной для конкретного Пользователя. Кроме того, Провайдер оставляет за собой право запрашивать у
          Пользователей актуальные документы, даже если они прошли проверку подлинности в прошлом.
        </p>
        <p className={style.text}>
          Информация об идентификации пользователя будет собираться, храниться, совместно использоваться и защищаться
          строго в соответствии применимыми законами. Пользователь соглашается с тем, что если какая-либо
          предоставленная информация изменится, перестанет быть правдивой или станет существенно вводящей в заблуждение,
          то в таком случае Пользователь уведомит Провайдера о таких изменениях.
        </p>
        <p className={style.text}>
          После подтверждения личности Пользователя, Провайдер может отказаться от предоставления Сервиса Пользователю в
          ситуации, когда подозревается, что Сервис используется для ведения незаконной деятельности.
        </p>
        <p className={style.text}>
          Провайдер имеет нормативное требование о проверке источника Фиатной валюты и/или Криптовалюты, чтобы знать,
          что источники Активов, которые Пользователи используют для торговли, являются законными. Провайдер может
          потребовать подтверждение источника Активов: это могут быть банковская выписка для Фиатной валюты или
          материалы, показывающие детали Транзакции кошелька, для Криптовалют.
        </p>
        <p className={style.text}>
          Пользователи, которые намерены использовать банковские карты с целью совершения операций, должны пройти
          проверку карты в соответствии с инструкциями, доступными на Сервисе.
        </p>
        <p className={style.text}>Провайдер использует независимые сторонние компании в следующих целях:</p>
        <p className={style.text}>
          проверка Пользователей по спискам политически значимых лиц и санкционным спискам; оценка Криптовалютных
          кошельков Пользователя на предмет расследования и соблюдения требований AML. Провайдер оставляет за собой
          право закрыть Баланс или отклонить операцию на основе информации, полученной сторонними компаниями, включая
          Пользователей, подключенных к кошелькам с высоким риском.
        </p>
        <p className={style.text}>
          Провайдер назначает ответственное должностное лицо, чья обязанность заключается в обеспечении эффективного
          внедрения и обеспечения соблюдения Политики. Обязанностью такого ответственного должностного лица является
          контроль за всеми аспектами деятельности Провайдера по противодействию легализации доходов, добытых преступным
          путем, финансированию терроризма, включая, но не ограничиваясь, нижеследующими методами:
        </p>
        <p className={style.text}>
          сбор идентификационной информации Пользователей; создание и обновление внутренних политик и процедур для
          завершения, рассмотрения, представления и хранения всех отчетов и записей, требуемых в соответствии с
          применимыми законами и правилами; мониторинг операций и исследование любых существенных отклонений от
          нормальной деятельности; внедрение системы управления записями для соответствующего хранения и поиска
          документов, файлов, форм и журналов, регулярное обновление системы оценки рисков, предоставление
          правоохранительным органам информации, необходимой в соответствии с применимыми законами и правилами.
          Ответственное должностное лицо является контактным лицом Провайдера при взаимодействии с правоохранительными
          органами, в целях предотвращения отмывания денег, финансирования терроризма и другой незаконной деятельности.
        </p>
        <p className={style.text}>
          Пользователи должны пройти процесс подтверждения личности перед совершением операций. После завершения
          проверки, Пользователь дает согласие на мониторинг операций.
        </p>
        <p className={style.text}>
          Провайдер полагается на анализ данных как на инструменты оценки рисков и обнаружения подозрительной
          активности. Эти инструменты выполняют множество задач, связанных с соблюдением нормативных требований, включая
          сбор данных, фильтрацию, ведение записей, управление расследованиями и отчетность.
        </p>
        <p className={style.text}>В соответствии с Политикой, Провайдер будет:</p>
        <p className={style.text}>
          осуществлять мониторинг всех или отдельных операций. Провайдер оставляет за собой право обеспечивать передачу
          сообщений о подозрительном характере операций надлежащим правоохранительным органам через ответственное
          должностное лицо; запрашивать у Пользователя любую дополнительную информацию и документы в случае
          осуществления им подозрительных операций; приостанавливать или прекращать действие Личного кабинета
          Пользователя, если у Провайдера есть разумное подозрение, что такой Пользователь участвует в незаконной
          деятельности. При этом вышеприведенный список не является исчерпывающим, и ответственное должностное лицо
          будет регулярно отслеживать операции Пользователей, чтобы определить, следует ли сообщать о таких операциях и
          рассматривать их как подозрительные или которые должны рассматриваться как добросовестные.
        </p>
        <p className={style.text}>
          Для выполнения своих обязательств по борьбе с отмыванием денег (AML) и противодействию финансированию
          терроризма (CTF), Провайдер проводит регулярную оценку рисков AML. Цель оценки рисков AML — не допустить,
          чтобы преступники использовали Сервис для отмывания денег, выделяя риски и оценивая меры контроля,
          установленные Провайдером. Подход, основанный на оценке риска, используется для идентификации Пользователей и
          отслеживания того, как они пользуются Сервисом.
        </p>
        <p className={style.text}>
          Ответственное должностное лицо отвечает за управление рисками финансовых преступлений и внесение улучшений в
          управление рисками финансовых преступлений путем выявления общих и конкретных рисков отмывания денег, с
          которыми сталкивается Провайдер, определения того, как эти риски смягчаются средствами контроля AML, и
          определения остаточного риска, который остается за Провайдером.
        </p>
        <p className={style.text}>
          Провайдер не принимает клиентов со спорных или непризнанных территорий, поскольку идентификационные документы
          данных территорий не являются признанными официальными документами.
        </p>
        <p className={style.text}>
          Провайдер не предоставляет консультаций по инвестициям. Вся информация, предоставленная на Сервисе, носит
          информационный, а не рекомендательный характер. Любые решения в отношении операций принимаются Пользователем
          самостоятельно.
        </p>
      </>
    )}

    {content === 'customerAgreement' && (
      <>
        <h1 className={style.heading}>Пользовательское соглашение</h1>
        <p className={style.text}>
          Данное Пользовательское соглашение (далее — «Соглашение») является электронным договором между Вами («Вы»,
          «Пользователь») и администратором Сервиса (далее — «Провайдер») относительно использования Вами электронных
          сервисов, представленных на интернет—сайте https://force-capital.ru/(далее — «Сервис»).
        </p>
        <p className={style.text}>
          Используя Сервис, Вы подтверждаете свое согласие с тем, что Вы ознакомились, понимаете и полностью принимаете
          условия Соглашения в действующей редакции с учетом возможных последующих изменений и дополнений к нему.
        </p>
        <p className={style.text}>
          Внимательно ознакомьтесь с условиями Соглашения до начала и при каждом использовании Сервиса. Соглашение
          считается заключенным с момента регистрации.
        </p>
        <p className={style.text}>
          Возможность использования Сервиса может быть прекращена или ограничена Провайдером в любой момент времени без
          объяснения причин. Провайдер вправе проводить дополнительные и комплексные проверки Пользователей с целью
          выявления рисков Провайдера при предоставлении Сервиса.
        </p>
        <p className={style.text}>
          Пользователи самостоятельно несут ответственность за соблюдение законов, применимых к использованию Сервиса.
        </p>
        <p className={style.text}> 1. Термины и определения</p>
        <p className={style.text}>
          Криптовалюта — криптографически защищенное цифровое выражение условной стоимости или договорных прав, которое
          использует технологию Блокчейн и может передаваться, храниться или продаваться в электронном виде.
        </p>
        <p className={style.text}>Актив — означает Криптовалюту и/или Фиатную валюту.</p>
        <p className={style.text}>
          Транзакция — запись в Блокчейне о совершении операции операция по Депозиту или Выводу.
        </p>
        <p className={style.text}>Депозит — операция по пополнению суммы Актива на Балансе из внешнего источника.</p>
        <p className={style.text}>Вывод — операция по списанию суммы Актива с Баланса за пределы Сервиса.</p>
        <p className={style.text}>
          Сделка — операция по покупке или продаже Активов между Пользователями в рамках Сервиса.
        </p>
        <p className={style.text}>Ордер — поручение Пользователя на осуществление Сделки.</p>
        <p className={style.text}>
          Лимитный Ордер — распоряжение Мейкера на совершение Сделки по установленной Мейкером цене или более выгодной
          цене.
        </p>
        <p className={style.text}>
          Комиссия — любой тип вознаграждения, подлежащие оплате Пользователями в пользу Провайдера.
        </p>
        <p className={style.text}>
          Фиатная валюта — валюта, эмитированная государством, определенная на законодательном уровне как законное
          платежное средство в стране ее эмиссии.
        </p>
        <p className={style.text}>Пользователь — любое лицо, которое зарегистрировано на Сервисе.</p>
        <p className={style.text}>
          Соглашение — настоящее Пользовательское соглашение и его неотъемлемые части, с учетом всех дополнений и
          изменений.
        </p>
        <p className={style.text}>
          Баланс — функциональная часть Сервиса, предназначенная для учета Активов, доступная через Личный кабинет, в
          котором отображается информация по размещенным и отмененным Ордерам, совершенным операциям, доступным Активам
          и другая информация, определенная функциональными возможностями Сервиса.
        </p>
        <p className={style.text}>
          2FA — метод дополнительной безопасности Личного кабинета, доступный для подключения Пользователю путём
          привязки устройства или приложения и позволяющий подтверждать операции или получать доступ к Личному кабинету
          с помощью дополнительного кода, предоставленного подключенным устройством или приложением.
        </p>
        <p className={style.text}>
          Блокчейн — технология электронного хранения данных, которые хранятся в цепочке последовательно связанных
          блоков данных, при этом каждый блок содержит указатель на предыдущий блок и комбинацию информации об
          операциях, временных меток и других метаданных для подтверждения его достоверности.
        </p>
        <p className={style.text}>
          Мейкер — Пользователь, создающий Лимитные или иные аналогичные Ордера, подлежащие Заполнению по определенной
          Пользователем цене в случае покупки.
        </p>
        <p className={style.text}>
          Тейкер — Пользователь, совершающий покупку Актива по Лимитному или аналогичному Ордеру.
        </p>
        <p className={style.text}>
          Личный кабинет — набор защищенных страниц на Сервисе, созданных после регистрации Пользователя на Сервисе,
          используя которые Пользователь может совершать операции и управлять Балансом.
        </p>
        <p className={style.text}>
          Идентификация — процесс подтверждения соответствия Пользователя установленным Провайдером требованиям.
        </p>
        <p className={style.text}>
          Ask1 — обозначение в Книге Ордеров, означающее первое предложение в столбце ценовых уровней с наилучшей ценой
          для покупки.
        </p>
        <p className={style.text}>
          Bid1 — обозначение в Книге Ордеров, означающее первый Ордер в столбце ценовых уровней с наилучшей ценой для
          продажи.
        </p>
        <p className={style.text}>
          Резервирование Активов — это блокирование суммы Активов для гарантированного совершения операции.
        </p>
        <p className={style.text}>
          Котируемый Актив — это Актив, стоящий второй в паре Активов, и в единицах которого измеряется стоимость
          Базового (первого) Актива.
        </p>
        <p className={style.text}>Базовый Актив — это Актив, стоящий первым в Торговой паре.</p>
        <p className={style.text}>
          Книга Ордеров — очередь из Ордеров Пользователей по одной Торговой паре, отсортированных по цене,
          сгруппированная в группу на покупку и на продажу.
        </p>
        <p className={style.text}>
          Проскальзывание — разница в цене между первым и последним Заполненным Ордером при Заполнении рыночного Ордера.
        </p>
        <p className={style.text}>
          Заполнение Ордера — автоматическое заключение Сделок для выполнения условий, установленных Пользователем при
          создании Ордера.
        </p>
        <p className={style.text}>
          В настоящем Соглашении Провайдер и Пользователь упоминаются в совокупности как «Стороны» и по отдельности —
          как «Сторона».
        </p>
        <p className={style.text}>2. Регистрация, Идентификация и верификация</p>
        <p className={style.text}> Регистрация</p>
        <p className={style.text}>
          2.1. При регистрации на Сервисе Пользователь обязуется предоставлять полную и точную информацию, и обязуется
          своевременно обновлять данную информацию.
        </p>
        <p className={style.text}>
          2.2. Каждый Пользователь может зарегистрировать только один Личный кабинет и не может пользоваться двумя или
          более Личными кабинетами.
        </p>{' '}
        <p className={style.text}>
          2.3. Провайдер вправе ограничить доступ к Личному кабинету при наличии подозрений о его несанкционированном
          использовании. Пользователь должен немедленно уведомить Провайдера о несанкционированном использовании Личного
          кабинета, если Пользователь подозревает факт несанкционированного использования.
        </p>{' '}
        <p className={style.text}>
          2.4. Пользователь самостоятельно задает пароль для доступа к Личному кабинету и несёт ответственность за его
          надежность, а также за сохранность данных, необходимых для доступа к Личному кабинету.
        </p>{' '}
        <p className={style.text}>Идентификация</p>
        <p className={style.text}>
          2.5. Использование всех функций Личного кабинета недоступно для Пользователей, не прошедших Идентификацию.
        </p>
        <p className={style.text}>
          2.6. Для прохождения Идентификации Пользователь должен соответствовать следующим минимальным требованиям:
        </p>
        <p className={style.text}>
          быть старше 18 лет; не являться гражданином государства, в котором использование Сервиса незаконно или
          ограничено; не являться лицом, включенным в санкционные списки OFAC или Министерства торговли РФ; не являться
          гражданином страны и не проживать на территории, которые, по мнению Провайдера, могут представлять повышенные
          риски.
        </p>{' '}
        <p className={style.text}>2.7. При прохождении Идентификации Пользователь обязан:</p>
        <p className={style.text}>заполнить все поля, помеченные как обязательные;</p>
        <p className={style.text}> НЕОБЯЗАТЕЛЬНО:</p>
        <p className={style.text}>
          2.8. Документ, удостоверяющий личность, должен содержать все предусмотренные законом реквизиты в читаемой
          форме. Для прохождения Идентификации Провайдер принимает следующие документы, удостоверяющие личность:
        </p>
        <p className={style.text}>
          общегражданский паспорт (разворот страницы с фото); заграничный паспорт (разворот страницы с фото);
          водительское удостоверение.
        </p>
        <p className={style.text}>
          2.9. На загруженных фотографиях должны быть отчетливо видны все детали, должны отсутствовать следы
          редактирования, документ должен быть видео полностью и не должен прекращать своё действие ранее, чем через 30
          дней со дня его загрузки.
        </p>
        <p className={style.text}>
          2.10. Личное изображение лица Пользователя должно быть сделано с загруженным документом, удостоверяющим
          личность. Лицо и все реквизиты документы должны быть видны отчётливо без искажений.
        </p>
        <p className={style.text}>
          2.11. Файлы могут быть загружены в формате jpg или png. Размер загружаемых файлов не должен превышать 10Мб.
        </p>
        <p className={style.text}>
          2.12. Пользователь обязуется использовать достоверные сведения при прохождении Идентификации и обязуется
          поддерживать их актуальность.
        </p>
        <p className={style.text}>
          2.13. После проверки предоставленных сведений Пользователь получает уведомление на электронную почту,
          привязанную к Личному кабинету, а также видит результат Идентификации в Личном кабинете.
        </p>
        <p className={style.text}>
          2.14. В случае успешной Идентификации Пользователь получает доступ к ранее недоступным функциям Сервиса. При
          этом для открытия дополнительных функций Провайдер вправе требовать прохождения расширенной Идентификации.
        </p>
        <p className={style.text}>
          2.15. В случае сомнений в достоверности предоставленных сведений или плохого качества фотографий Провайдер
          вправе отказать в прохождении Идентификации.
        </p>
        <p className={style.text}>
          2.16. Провайдер вправе требовать проведения повторной Идентификации или аннулировать Идентификацию без
          объяснения причин, в том числе в случае успешной Идентификации.
        </p>
        <p className={style.text}>2.17. Провайдер вправе предъявить к идентификации дополнительные требования.</p>
        <p className={style.text}> Верификация</p>
        <p className={style.text}>
          2.18. Для совершения операций по Депозиту и Выводу Фиатных валют с использованием банковской карты, карта
          должна быть верифицирована. Процедура верификации карты состоит в предоставлении Пользователем данных,
          подтверждающих владение банковской картой. Время прохождения верификации при условии предоставления корректных
          данных составляет до 1 часа.
        </p>
        <p className={style.text}>
          2.19. Для прохождения верификации банковской карты необходимо заполнить соответствующие поля в форме
          верификации в Личном кабинете и отправить фотографию карты, соответствующую следующим требованиям:
        </p>
        <p className={style.text}>
          на именной карте должны быть отчетливо видны имя и фамилия владельца; должны быть отчетливо видны 4 последние
          цифры карты, соответствующие номеру, указанному в форме, при этом остальные реквизиты карты можно скрыть;
          фотография должна быть отправлена в формате jpg или png размером до 10 Мб.
        </p>{' '}
        <p className={style.text}>
          2.20. Провайдер оставляет за собой право отказать в прохождении верификации банковской карты или запросить
          новое прохождение в одностороннем порядке без объяснения причин.
        </p>
        <p className={style.text}>3. Общие условия совершения операций</p>{' '}
        <p className={style.text}>
          3.1. Пользователи, успешно прошедшие процедуру Идентификации, получают возможность совершения операций.
        </p>
        <p className={style.text}>
          3.2. Совершение операций влечёт изменение Баланса. История операций отображается в Личном кабинете в разделах
          Отчеты https://force-capital.ru/). Сверка взаиморасчетов между Пользователем и Провайдером доступна в Личном
          кабинете в разделе Отчеты/История балансов (https://force-capital.ru/).
        </p>
        <p className={style.text}>3.3. Для различных типов Активов время обработки операций может отличаться.</p>
        <p className={style.text}>
          3.4. При превышении времени обработки, установленного на Сервисе, операция может быть отменена Провайдером.
          Если операция соответствует всем требованиям, но не была завершена, Пользователь вправе обратиться в службу
          поддержки по адресу https://force-capital.ru/ или в онлайн—чате.
        </p>
        <p className={style.text}>
          3.5. При подозрении в нарушении AML/KYC политик (https://force-capital.ru/) Провайдер вправе приостановить
          зачисление Активов на Баланс и потребовать документы о происхождении Активов. Для целей оценки операции
          Провайдер вправе передавать данные Пользователя третьим лицам.
        </p>
        <p className={style.text}>
          3.6. Провайдер устанавливает дневные и месячные лимиты на Вывод Средств, размер которых указан на странице
          hhttps://force-capital.ru/
        </p>
        <p className={style.text}>
          3.7. Провайдер не несёт ответственности и не гарантирует выполнение или оплату по какой—либо операции,
          совершенной Пользователем на Сервисе.
        </p>
        <p className={style.text}>
          3.8. Провайдер вправе отказать в Выводе Активов на конкретный адрес или по конкретным реквизитам при наличии
          подозрений в нарушении AML—политики Провайдера. Для оценки операции в целях защиты от незаконного
          использования Активов Провайдер использует различные аналитические инструменты и вправе передавать данные
          Пользователя третьим лицам.
        </p>
        <p className={style.text}>4. Депозиты и Выводы Криптовалют</p>{' '}
        <p className={style.text}>
          4.1. При Депозитах и Выводах Криптовалют Сервис ожидает получения необходимого количества подтверждений от
          сети Блокчейн. Ознакомиться с требуемым количеством подтверждений можно на странице состояния сети
          https://force-capital.ru/
        </p>
        <p className={style.text}>
          4.2. При совершении Вывода сумма Криптовалюты, вознаграждение сети Блокчейн и Комиссия Провайдера
          резервируется для предотвращения использования данных Активов в других операциях. После завершения операции
          сумма Активов списывается с Баланса.
        </p>{' '}
        <p className={style.text}>4.3. Операции по Депозиту Криптовалюты имеют следующие статусы:</p>{' '}
        <p className={style.text}>
          «ожидает подтверждений», в ходе которого осуществляется обработка операции и Баланс не меняется; «зачислен»,
          что означает завершение обработки и увеличение Баланса; «отменён», что означает исключение операции из
          обработки, при этом Баланс не меняется.
        </p>{' '}
        <p className={style.text}>4.4. Операции по Выводу Криптовалюты имеют следующие статусы:</p>
        <p className={style.text}>
          «создан», что означает создание операции и резервирование Активов; «в обработке», что означает подготовку
          операции к совершению; «отправляется», что означает создание Транзакции и её отправку в Блокчейн; «отправлен»,
          что означает завершение передачи Транзакции в Блокчейн, снятие резервирования и уменьшение Баланса; «отменён»,
          что означает отмену операции.
        </p>{' '}
        <p className={style.text}>4.5. Наименования статусов операций в интерфейсе Сервиса могут отличаться.</p>
        <p className={style.text}>
          4.6. Пользователь может отменить Вывод только при статусе «создан» или «в обработке».
        </p>
        <p className={style.text}>
          4.7. При совершении операции по Депозиту Криптовалюты Провайдер может установить минимальную сумму Депозита.
          При Депозите на меньшую сумму Баланс не увеличивается, Криптовалюта не возвращается Пользователю и считается
          утерянной.
        </p>
        <p className={style.text}>
          4.8. Время обработки Транзакций в отношении Криптовалюты может отличаться и зависит от выбранной суммы
          вознаграждения сети Блокчейн и нагрузки на Блокчейн. Время обработки Транзакций в отношении Криптовалюты не
          зависит от Провайдера. Пользователю рекомендуется устанавливать размер вознаграждения сети Блокчейн не меньше
          рекомендуемого сторонними сервисами аналитики, в противном случае время зачисления может быть дольше
          прогнозируемого. Размер вознаграждения сети Блокчейн напрямую влияет на скорость включения Транзакции в сеть
          Блокчейн. При этом Провайдер не может гарантировать срок включения Транзакции в сеть Блокчейн, поскольку срок
          не рассчитывается Провайдером и является прогнозируемым.
        </p>
        <p className={style.text}>
          4.9. Иные особенности обработки Транзакций в отношении Криптовалюты указаны на страницах сайта.
        </p>
        <p className={style.text}>
          4.10. Пользователь самостоятельно несет ответственность и риск за правильность выбора адреса и сети для
          совершения операции в отношении Криптовалюты. Если Криптовалюта была отправлена на ошибочный адрес или при
          оформлении операции была выбрана неправильная сеть Блокчейн, Криптовалюта будет утеряна без возможности
          возврата.
        </p>
        <p className={style.text}>
          4.11. Для совершения Вывода Криптовалюты с Баланса Пользователь должен выбрать тип Криптовалюты и указать
          параметры Вывода. В процессе совершения Вывода Баланс уменьшается на выбранную сумму. Получатель получает
          сумму Активов за вычетом вознаграждения сети Блокчейн и Комиссии Провайдера.
        </p>
        <p className={style.text}>
          4.12. Обязательства Провайдера по Выводу считаются исполненными полностью, когда Транзакция появляется в сети
          Блокчейн. Данные публичного реестра Блокчейн сети являются единственным и достоверным подтверждением
          Транзакции.
        </p>
        <p className={style.text}>5. Депозит и Вывод Фиатных валют</p>{' '}
        <p className={style.text}>
          5.1. Пользователи, успешно прошедшие Идентификацию, вправе совершать операции по Депозиту и Выводу Фиатных
          валют в российских рублях.
        </p>{' '}
        <p className={style.text}>
          5.2. При совершении Депозита Фиатных валют Провайдер вправе установить ограниченное время для совершения
          оплаты. В случае отсутствия оплаты в течение установленного времени, операция отменяется.
        </p>
        <p className={style.text}>
          5.3. Операции по Депозиту с банковской карты возможны только с верифицированной карты. При попытке пополнить
          Баланс с другой карты операция будет отменена.
        </p>
        <p className={style.text}>5.4. Операции по Депозиту в Фиатной валюте имеют следующие статусы:</p>
        <p className={style.text}>
          «ожидает», что означает ожидание оплаты; «зачислен», что означает успешный Депозит; «отменён», что означает
          отсутствие оплаты в отведённое время.
        </p>{' '}
        <p className={style.text}>5.5. Операции по Выводу Фиатной валюты с Баланса имеют следующие статусы:</p>
        <p className={style.text}>
          «создан», что означает создание операции; «в обработке», что означает подготовку к Выводу; «отправляется», что
          означает нахождение Активов в процессе отправки; «отправлен», что означает успешную отправку Активов.
          «отменён», что означает отмену операции Пользователем.
        </p>{' '}
        <p className={style.text}>5.6. Наименования статусов в интерфейсе Сервиса могут отличаться.</p>
        <p className={style.text}>
          5.7. Операции по Депозиту и Выводу Фиатной валюты совершаются с минимальной задержкой. В отдельных случаях
          срок выполнение операции может занять до 5 рабочих дней по независящим от Провайдера причинам.
        </p>
        <p className={style.text}>6. Комиссии Провайдера</p>{' '}
        <p className={style.text}>
          6.1. Размеры Комиссии Провайдера за совершение операций установлены на странице по адресу https://force-capital.ru/
        </p>
        <p className={style.text}>
          6.2. Комиссия удерживается за каждую операцию в виде части Актива операции и рассчитывается как процент от
          суммы операции.
        </p>
        <p className={style.text}>6.3. При совершении Вывода Комиссия удерживается из выводимой суммы Актива.</p>{' '}
        <p className={style.text}>7. Торговля</p>{' '}
        <p className={style.text}>
          7.1. Сервис позволяет совершать Сделки между Пользователями в роли Тейкеров или Мейкеров.
        </p>{' '}
        <p className={style.text}>
          7.2. За совершение Сделок Провайдер взимает Комиссию. Комиссия удерживается за каждую Сделку в валюте Сделки и
          рассчитывается как процент от суммы Сделки.
        </p>{' '}
        <p className={style.text}>
          7.3. Размеров Комиссии для Тейкеров и Мейкеров может отличаться и меняться.
        </p>
        <p className={style.text}>
          7.4. Пользователь признаёт, что Ордер должен быть создан только после его тщательной оценки, и Пользователь
          понимает и принимает все последствия его Заполнения.
        </p>{' '}
        <p className={style.text}>8. Типы Ордеров</p>{' '}
        <p className={style.text}>8.1. Сервис позволяет Пользователям создавать следующие Ордеры:</p>
        <p className={style.text}>рыночный Ордер; рыночный стоп—Ордер; лимитный Ордер; лимитный стоп—Ордер.</p>{' '}
        <p className={style.text}>9. Рыночный Ордер</p>{' '}
        <p className={style.text}>
          9.1. Рыночный Ордер — это распоряжение Пользователя на немедленную покупку или продажу Актива по текущей
          рыночной цене. Рыночный Ордер будет автоматически Заполняться по лимитным Ордерам с лучшей ценой до полного
          Заполнения.
        </p>{' '}
        <p className={style.text}>
          9.2. Цена Заполнения рыночного Ордера представляет собой среднюю цену всех Сделок при Заполнении Ордера.
          Рыночный Ордер Заполняется по ценам, которые предлагают Мейкеры на момент создания Ордера.
        </p>
        <p className={style.text}>9.3. За Заполнение рыночного Ордера с Пользователя взимается Комиссия Тейкера.</p>
        <p className={style.text}>
          9.4. Резервирование Активов для рыночных Ордеров происходит по цене Ask1 для продажи и Bid1 для покупки,
          включая Комиссию.
        </p>
        <p className={style.text}>
          9.5. При использовании всех доступных Активов одного типа на Балансе резервирование происходит:
        </p>
        <p className={style.text}>
          для покупки — по цене Ask1 + коэффициент Проскальзывания; для продажи — по цене Bid1- коэффициент
          Проскальзывания.
        </p>{' '}
        <p className={style.text}>10. Стоп—рыночный Ордер</p>{' '}
        <p className={style.text}>
          10.1. Стоп—рыночный Ордер — это отложенное распоряжение Пользователя на Заполнение рыночного Ордера, при
          котором рыночный Ордер будет создан и будет незамедлительно Заполнен по лучшей цене, когда рыночная цена
          Актива достигнет установленной Пользователем стоп—цены.
        </p>
        <p className={style.text}>
          10.2. Резервирование Активов для стоп—рыночных Ордеров происходит по установленной Пользователем цене, включая
          Комиссию.
        </p>
        <p className={style.text}> 11. Лимитный Ордер</p>
        <p className={style.text}>
          11.1. Лимитный Ордер — это отложенное распоряжение Пользователя на Заполнение Ордера, при котором Ордер будет
          Заполнен при достижении Активом указанной Пользователем цены или более выгодной цены.
        </p>
        <p className={style.text}>
          11.2. Резервирование Активов для лимитного Ордера происходит по установленной Пользователем цене Ордера.
        </p>
        <p className={style.text}>
          11.3. При Заполнении размещённого лимитного Ордера с Пользователя взимается комиссия Мейкера.
        </p>
        <p className={style.text}>
          11.4. Если рыночная цена на момент подачи распоряжения оказалась выгоднее указанной Пользователем цены, такой
          Ордер будет Заполнен полностью или частично. За Заполненный таким образом объем Ордера будет удержана комиссия
          Тейкера, при этом в случае недостатка Активов на Балансе для оплаты Комиссии, Комиссия будет удержана в
          эквиваленте базовой валюты.
        </p>
        <p className={style.text}>12. Стоп—лимитный Ордер</p>
        <p className={style.text}>
          12.1. Стоп—лимитный Ордер — это отложенное распоряжение Пользователя на совершение Сделки по указанной
          Пользователем цене. Ордер будет размещен в случае, если рыночная цена Актива достигнет указанной Пользователем
          стоп—цены или более выгодной цены. После достижения стоп—цены, размещённый Ордер ожидает своего Заполнения как
          лимитный Ордер.
        </p>
        <p className={style.text}>
          12.2. Резервирование Активов для стоп—лимитного Ордера происходит по установленной Пользователем лимитной цене
          Ордера.
        </p>
        <p className={style.text}>
          12.3. За размещённый Ордер при его Заполнении с Пользователя взимается Комиссия Мейкера.
        </p>
        <p className={style.text}>
          12.4. Если рыночная цена на момент подачи распоряжения оказалась выгоднее указанной Пользователем цены, такой
          Ордер будет Заполнен незамедлительно, полностью или частично. За Заполненный таким образом объем Ордера будет
          удержана комиссия Тейкера, при этом в случае недостатка Активов на Балансе для оплаты Комиссии, Комиссия будет
          удержана в эквиваленте базовой валюты.
        </p>
        <p className={style.text}>13. Проскальзывание</p>
        <p className={style.text}>
          13.1. При использовании Сервиса максимальный размер КОМИССИИ составляет 20% , СУММЫ ДОХОДА
        </p>
        <p className={style.text}>
          13.2. Если на момент Заполнения значение Проскальзывание достигает максимального показателя, то Заполнение
          осуществляется частично на объём в пределах размера Проскальзывания.
        </p>
        <p className={style.text}>14. Учет и резервирование Активов при торговле</p>{' '}
        <p className={style.text}>
          14.1. Провайдер резервирует Активы для выполнения операции, что влечёт уменьшение Баланса для исключения риска
          задвоения операций.
        </p>
        <p className={style.text}>14.1. В случае отмены операции доступный Баланс увеличивается.</p>
        <p className={style.text}>15. Частичное Заполнение Ордера</p>{' '}
        <p className={style.text}>15.1. Частичное Заполнение Ордера может произойти по причинам:</p>
        <p className={style.text}>
          при Заполнении превышен размер Проскальзывания для указанного объема Ордера; при Заполнении резервируются все
          доступные Активы, при этом реестр Ордеров меняется таким образом, что зарезервированных Активов не хватает на
          Заполнение всего объема Ордера; Пользователь отменяет Ордер, который был Заполнен не на весь объем.
        </p>{' '}
        <p className={style.text}>16. Отмена Ордеров Провайдером</p>{' '}
        <p className={style.text}>
          16.1. Провайдер имеет право отменять Ордера Пользователя при проведении технических работ или при блокировке
          Личного кабинета.
        </p>
        <p className={style.text}>17. Восстановление доступа к Личному кабинету</p>{' '}
        <p className={style.text}>
          17.1. В случае, если Пользователь не может авторизоваться на Сервисе, он может пройти следующие операции
          восстановления доступа:
        </p>
        <p className={style.text}>
          восстановление пароля; смена электронной почты; потеря доступа к устройству с 2FA; вход с использованием IP
          адреса, не входящего в список разрешенных; разблокировка Личного кабинета.
        </p>{' '}
        <p className={style.text}>
          17.2. Для восстановления доступа к аккаунту Пользователю необходимо воспользоваться функционалом на странице
          авторизации в разделе “Помощь”.
        </p>
        <p className={style.text}>18. Поддержка Пользователей</p>{' '}
        <p className={style.text}>
          18.1. Техническая поддержка Пользователей осуществляется круглосуточно через и https://force-capital.ru/ ИЛИ ПО ТЕЛ
          +79454773359
        </p>
        <p className={style.text}>
          18.2. Провайдер прикладывает разумные усилия для оперативных ответов на запросы в порядке очереди. Время
          ответа зависит от нагрузки на операторов.
        </p>
        <p className={style.text}>19. Блокировки</p>{' '}
        <p className={style.text}>
          19.1. Блокировка представляет собой меры, направленные на частичное или полное ограничение доступа к
          функционалу Сервиса.
        </p>
        <p className={style.text}>19.2. Возможные причины блокировки:</p>
        <p className={style.text}>
          нарушение политик KYC/AML; целенаправленные вредоносные действия в отношении Сервиса; подозрения на взлом
          Личного кабинета или компрометации данных для доступа.
        </p>{' '}
        <p className={style.text}>
          19.3. Провайдер вправе ограничить доступ к операциям или полностью заблокировать Личный кабинет Пользователя в
          случае установления фактов нарушения Пользователем данного Соглашения.
        </p>
        <p className={style.text}>
          19.4. При полной блокировке Личного кабинета для Пользователя приостанавливаются все операции, включая Сделки,
          Депозиты и Выводы, снимаются активные Ордера, запрещается вход в Личный кабинет.
        </p>
        <p className={style.text}>
          19.5. Для выяснения причин блокировки и подачи заявки на разблокировку Пользователь вправе обратиться в службу
          поддержки. Окончательное решение о разблокировке остается за Провайдером.
        </p>
        <p className={style.text}>20. Техническое обслуживание</p>{' '}
        <p className={style.text}>
          20.1. Для обеспечения функционирования Сервиса Провайдер вправе проводить техническое обслуживание по
          запланированному графику или в случае непредвиденных обстоятельств.
        </p>
        <p className={style.text}>
          20.2. При технических работах доступ к всему функционалу Сервиса или к его части может быть ограничен.
        </p>
        <p className={style.text}>
          20.3. Провайдер не гарантирует, что Сервис будет доступен 100% времени и допускает возможность появление
          ошибок или сбоев. Провайдер не несет ответственности за возможные финансовые потери Пользователей в результате
          технических сбоев. При этом Провайдер принимает все разумные усилия для обеспечения непрерывной работы Сервиса
          и стабильного качества предоставляемого Сервиса.
        </p>
        <p className={style.text}>
          20.4. Пользователь признает, что Сервис может быть временно недоступен или иметь технические сбои, что может
          привести к финансовым убыткам, и принимает на себя связанные с этим риски.
        </p>
        <p className={style.text}>21. Взлом Личного кабинета</p>{' '}
        <p className={style.text}>
          21.1. Взлом Личного кабинета — это неправомерное получение доступа к Личном кабинету Пользователя третьими
          лицами.
        </p>
        <p className={style.text}>
          21.2. Провайдер стремится обеспечить разумно возможный уровень безопасности для Пользователя и предоставляет
          функционал, позволяющий защитится от неправомерного доступа, что не полностью не исключает вероятность взлома.
        </p>
        <p className={style.text}>
          21.3. Пользователь принимает риск того, что в результате взлома возможна частичная или полная потеря Активов.
          Пользователь обязуется незамедлительно сообщать Провайдеру о подозрениях на взлом Личного кабинета.
        </p>
        <p className={style.text}>
          21.4. Провайдер не несет ответственности за взлом Личного кабинета и за любые финансовые потери, произошедшие
          во время получения доступа к Личному кабинету.
        </p>
        <p className={style.text}>
          21.5. Провайдер настоятельно рекомендует использовать все доступные средства защиты Личного кабинета,
          отслеживать список авторизованных устройств в Личном кабинете, проявлять внимательность и бдительность, а
          также сообщать Провайдеру о любых подозрительных действиях при работе с Личным кабинетом.
        </p>
        <p className={style.text}>
          21.6. При потере или хищении устройства, с которого ранее осуществлялся доступ к Личному кабинету, или
          устройства, на котором содержится приложение для 2FA—верификации, а также при взломе почтового аккаунта
          необходимо незамедлительно заблокировать Личный кабинет самостоятельно и сообщить о ситуации в службу
          поддержки.
        </p>
        <p className={style.text}>22. Уведомление о рисках</p>{' '}
        <p className={style.text}>
          22.1. Торговля криптовалютами несет высокий риск, связанный с существенным изменением цен торгуемых
          инструментов. Цена любой криптовалюты подвержена значительным колебаниям, включая полную потерю стоимости, что
          влечёт риск причинения убытков.
        </p>
        <p className={style.text}>
          22.2. Рынок криптовалюты не регулируется каким—либо органом, компанией или государством, что позволяет
          отдельным участникам рынка совершать манипуляции, приводящие к резкому изменению стоимости криптовалюты, а
          также ставит стоимость криптовалюты в зависимость от настроения участников торгов.
        </p>
        <p className={style.text}>
          22.3. Провайдер не предоставляет Пользователю какие—либо налоговые или финансовые консультации. Пользователь
          всегда будет нести ответственность за уплату всех налогов, причитающихся в результате совершения операций.
        </p>
        <p className={style.text}>
          22.4. Операции на Сервисе имеют необратимый характер, если Соглашением прямо не установлено иное. Возвраты по
          осуществленным операциям не осуществляются.
        </p>
        <p className={style.text}>
          22.5. Сервис является программным обеспечением, функциональность которого может изменяться, в котором могут
          содержаться ошибки, и который может быть несовместим с конфигурацией Вашего аппаратного и программного
          обеспечения.
        </p>
        <p className={style.text}>22.6. Данные риски не являются исчерпывающими.</p>
        <p className={style.text}>
          22.7. Пользователь осознает данные риски, безоговорочно принимает данное Соглашение и самостоятельно несёте
          ответственность за свои финансовые риски и убытки, за которые Провайдер ответственности не несёт.
        </p>
        <p className={style.text}>23. Незаконное использование</p>{' '}
        <p className={style.text}>
          23.1. Используя Сервис, Пользователь обязуется соблюдать и выполнять требования всех применимых законов и не
          будет совершать правонарушения. Пользователь самостоятельно несет ответственность за свое поведение при
          использовании Сервиса. Не ограничивая вышеизложенного, Пользователь соглашается, что не будет:
        </p>
        <p className={style.text}>
          использовать Сервис способом, который может нарушить, негативно повлиять или помешать другим Пользователям в
          полной мере использовать Сервис, или который может хоть как—то повредить, отключить, перегрузить или нарушить
          функционирование Сервиса; использовать Сервис для оплаты, поддержки или иного участия в любых незаконных
          азартных играх, мошенничестве, отмывании денег, в террористической деятельности или в других незаконных
          действиях; использовать любых роботов, программы—обходчики, скраперы или другие автоматизированные средства
          или интерфейсы, которые не были авторизованы нами для доступа к Сервису или для извлечения данных;
          использовать или пытаться использовать Личный кабинет или Баланс другого Пользователя; пытаться обойти любые
          методы фильтрации, которые использует Провайдер, или пытаться получить доступ к любой услуге или области
          Сервиса, к которым у Пользователя нет права доступа; разрабатывать любые сторонние приложения, которые
          взаимодействуют с Сервисом без получения от Провайдера предварительного письменного согласия; предоставлять
          ложную, неточную или вводящую в заблуждение информацию в процессе регистрации на Сервисе, при обновлении
          информации Пользователя или при коммуникации с Провайдером; рекламировать продукты на Сервисе без заключения
          дополнительного специального договора между с Провайдером; поощрять или побуждать любое третье лицо на участие
          в любой деятельности, запрещенной в соответствии с настоящим разделом Соглашения или запрещенной законом.
        </p>{' '}
        <p className={style.text}>24. Интеллектуальная собственность</p>{' '}
        <p className={style.text}>
          24.1. Провайдер является единственным собственником всех прав и интересов по отношению к правам
          интеллектуальной собственности на Сервис.
        </p>
        <p className={style.text}>24.2. Пользователь не вправе:</p>
        <p className={style.text}>
          менять, поддерживать, улучшать или иным образом модифицировать Сервис; дизассемблировать, декомпилировать,
          перепроектировать, копировать, исправлять ошибки, исправлять, обновлять, передавать, транслировать или
          создавать производные продуктовые решения на основе Сервиса; и предпринимать иные явные действия для создания
          аналогичного продукта на основе материалов Сервиса.
        </p>{' '}
        <p className={style.text}>
          24.3. Пользователь соглашается с использованием его личных данных на условиях и/или в целях исполнения
          настоящего Соглашения. В отношении обезличенных данных Провайдер вносить изменения, использовать,
          лицензировать и сублицензировать использование обезличенных данных на свое усмотрение.
        </p>
        <p className={style.text}>
          24.4. При условии соблюдения Пользователем условий настоящего Соглашения, Пользователю предоставляется
          ограниченная, отзывная, неисключительная и непередаваемая лицензия на доступ и использование Сервиса в
          соответствии с настоящим Соглашением.
        </p>
        <p className={style.text}>
          24.5. Все материалы Сервиса являются собственностью Провайдера и защищены авторским правом и другими
          применимыми законами.
        </p>
        <p className={style.text}>
          24.6. Товарные знаки, знаки обслуживания и логотипы Провайдера и другие, используемые на Сервисе («Торговые
          марки»), являются собственностью Провайдера. Программное обеспечение, тексты, изображения, графика, данные,
          видео и аудио, используемые на Сервисе, также принадлежат Провайдеру. Торговые марки и материалы не должны
          копироваться, воспроизводиться, изменяться, переиздаваться, загружаться, размещаться, передаваться, очищаться,
          собираться или распространяться в любой форме или любыми средствами, как ручными, так и автоматическими без
          предварительного согласия со стороны Провайдера. Использование любых таких материалов на любом другом
          веб—сайте или в сетевой компьютерной среде для любых других целей строго запрещено; любое такое
          несанкционированное использование может нарушать авторские права, права на торговые марки и другие применимые
          законы.
        </p>{' '}
        <p className={style.text}>25. Использование технологий</p>{' '}
        <p className={style.text}>
          25.1. Пользователь соглашается с тем, что Провайдер будет иметь абсолютный контроль над Сервисом, а также
          исключительное право произвести любые изменения их функциональности, конфигурации, внешнего вида и содержания,
          включая:
        </p>{' '}
        <p className={style.text}>
          параметры и протоколы, с помощью которых Ордеры и операции размещаются, маршрутизируются, сопоставляются или
          иным образом обрабатываются на Сервисе; наличие у Пользователя доступа к Сервису или права на осуществление
          операций в любом месте, в любое время или на любой территории; изменять адреса кошельков Криптовалюты
          Пользователей в их соответствующих Личных кабинетах с учетом требований безопасности, мер предосторожности,
          изменений инфраструктуры информационных технологий Сервиса или в случае любых других требований, которые
          Провайдер считает необходимыми по своему собственному усмотрению.
        </p>{' '}
        <p className={style.text}>
          25.2. Провайдер будет прилагать разумные усилия для обеспечения того, чтобы Пользователь мог получить доступ к
          Сервису в соответствии с настоящим Соглашением. Однако Провайдер приостановить использование Сервиса для
          технического обслуживания и предпримет разумные усилия, чтобы уведомить Пользователя об этом. Пользователь
          признает, что это может оказаться невозможным в чрезвычайной ситуации, и принимает на себя риски, связанные с
          тем, что Пользователь не всегда может использовать Сервис или совершать операции. Кроме того, периоды времени
          до и после технического обслуживания Сервиса могут быть подвержены высокой волатильности цен на валюту, что
          может привести к неожиданным движениям рынка. Принимая настоящее Соглашение, Пользователь признает такие риски
          и не будете привлекать Провайдера к ответственности за свои финансовые убытки в результате такой повышенной
          волатильности из—за временных работ по техническому обслуживанию Сервиса.
        </p>
        <p className={style.text}>
          25.3. Провайдер не делает никаких заявлений о каком—либо внешнем или стороннем веб—сайте, на который
          Пользователь может перейти через Сервис. Иногда, Провайдер не контролирует такие внешние сайты третьих лиц или
          какой—либо материал, содержащийся на них. Внешние сайты имеют отдельные и независимые условия использования и
          соответствующие политики.
        </p>
        <p className={style.text}>
          25.4. Любое использование Интернета может быть предметом вирусной атаки и/или сбоя связи. Провайдер не несет
          никакой ответственности за любой ущерб или перебои, вызванные компьютерными вирусами, шпионскими программами,
          троянскими конями, червями или другими вредоносными программами, которые могут повлиять на системы, компьютер
          или другое оборудование Пользователя, или любые фишинговые, спуфинговые или другие вирусные атаки. Провайдер
          рекомендует всегда использовать надежное и доступное программное обеспечение для проверки и предотвращения
          вирусов. Пользователю также следует проявлять осторожность при просмотре текстовых сообщений и электронных
          писем, которые якобы исходят от Провайдера, так как текстовые сообщения и электронные письма также уязвимы для
          фишинга, подмены и других атак. Провайдер рекомендуем входить в Личный кабинет только через Сервис и избегать
          сообщений от непроверенных отправителей, предлагающих иные варианты входа в Личный кабинет.
        </p>
        <p className={style.text}>
          25.5. Информация на Сервисе не всегда может быть полностью точной, полной или актуальной, а также может
          содержать технические неточности или опечатки. Пользователь должен проверять всю информацию, прежде чем
          полагаться на нее, и Провайдер не несет ответственности за принятие Пользователем решений на основе данной
          информации.
        </p>
        <p className={style.text}>
          25.6. Если Пользователь загружает какой—либо материал или отправляет сообщение на Сервис или с использованием
          Сервиса, Пользователь заявляет и гарантирует, что такой материал не будет состоять из:
        </p>
        <p className={style.text}>
          ложной, вводящей в заблуждение или незаконно полученной информации; защищенного авторским правом материала,
          который Пользователь не имеет права публично размещать; непристойного, оскорбительного, незаконного материала
          или любого другого материала, который, по усмотрению Провайдера, может повредить или поставить под угрозу
          репутацию Провайдера или других Пользователей.
        </p>{' '}
        <p className={style.text}>
          25.7. Провайдер вправе по собственному усмотрению удалять любые материалы, которые нарушают вышеуказанные
          требования, в дополнение к любым дальнейшим действиям, которые Провайдер может счесть необходимыми. Провайдер
          не обязуется проверять любые такие материалы и не несет никакой ответственности в связи с этим.
        </p>
        <p className={style.text}>
          25.8. Провайдер не подтверждает и не гарантирует подлинность, идентичность или надежность любого материала и
          информации, опубликованных любым Пользователем Сервиса. Пользователи полагаются на любые материалы других
          Пользователей исключительно на свое усмотрение и риск.
        </p>
        <p className={style.text}>26. Прекращение использования</p>{' '}
        <p className={style.text}>
          26.1. Пользователь вправе в одностороннем порядке расторгнуть настоящее Соглашение и закрыть свой Баланс в
          любой момент после проведения расчетов по всем незавершенным операциям, отправив письменный запрос Провайдеру
          за 30 (тридцать) календарных дней до желаемой даты прекращения. Данные Пользователя могут храниться
          Провайдером после прекращения Соглашения в целях защиты прав и законных интересов Провайдера.
        </p>
        <p className={style.text}>
          26.2. Провайдер вправе приостановить и ограничить доступ к Сервису полностью или в части при одном из
          следующих обстоятельств:
        </p>
        <p className={style.text}>
          полный или частичный отказ в работе Сервиса, в том числе отказ любой из технологий, составляющих Сервис, или
          любых коммуникационных каналов, связанных с Сервисом, или любое другое обстоятельство, при котором Провайдер
          считает по своему усмотрению, что не имеет возможности предоставить доступ к Сервису; нарушение
          конфиденциальности Сервиса; нарушение Пользователем взятых на себя обязательств по настоящему Соглашению; для
          соблюдения применимого закона; обнаружение подозрительной активности в Личном кабинете или Балансе;
          обнаружение несанкционированного доступа к Личному кабинету; в связи с процессуальными действиями со стороны
          государственных органов в отношении конкретного Баланса и/или Пользователя, уголовным расследованием или
          каким—либо судебным процессом; по решению суда или государственного органа; или в связи с рыночными условиями
          или условиями в отношении конкретного Актива в качестве необходимой меры по покрытию финансовых рисков.
        </p>{' '}
        <p className={style.text}>
          26.3. Любые действия по ограничению доступа к Сервису, предпринятые Провайдером, будут продолжаться в течение
          разумного срока, определенного Провайдером.
        </p>
        <p className={style.text}>26.4. Пользователь соглашается с тем, что:</p>{' '}
        <p className={style.text}>
          любое уклонение или любая попытка уклониться от ограничения доступа, установления лимитов или временного
          приостановления является существенным нарушением настоящего Соглашения; осуществление каких—либо действий по
          ограничению доступа является правом, а не обязательством Провайдера; при возникновении указанных обстоятельств
          Провайдер может отменить операцию, ранее выполненную с использованием Сервиса.
        </p>{' '}
        <p className={style.text}>
          26.5. Провайдер не будет нести никакой ответственности за неспособность Пользователя выполнить Депозит, Вывод
          или Сделку.
        </p>
        <p className={style.text}>27. Заявления и гарантии</p>{' '}
        <p className={style.text}>
          27.1. На дату заключения настоящего Соглашения и на дату осуществления каждой операции Пользователь заявляет и
          гарантирует, что:
        </p>
        <p className={style.text}>
          Ознакомился со всеми документами и информацией, предоставленной Пользователю при использовании Сервиса. Для
          целей исполнения условий настоящего Соглашения и всех операций, предусмотренных настоящим Соглашением, и
          выполнения всех обязательств, предусмотренных настоящим Соглашением, со стороны Пользователя были получены все
          надлежащие разрешения. Вся информация, предоставленная Пользователем Провайдеру, является достоверной, точной
          и не вводящей в заблуждение. Настоящее Соглашение и каждая операция представляют собой юридическое и
          действительное для Пользователя обязательство, подлежащее исполнению в соответствии с его условиями.
          Выполнение настоящего Соглашения и каждой операции, предусмотренной настоящим Соглашением, не будет нарушением
          применимого закона. Пользователь может принять или перевести полностью сумму Активов, требуемую для
          осуществления каждой операции, в соответствии с условиями такой операции. Пользователь обладает достаточным
          опытом и знаниями, необходимыми для принятия обоснованных решений в отношении операций, и не будет полагаться
          на какое—либо сообщение или заявление Провайдер в качестве совета по инвестированию или рекомендации для
          совершения какой—либо операции. При использовании Сервиса и совершении операций Пользователь действует от
          собственного имени.
        </p>{' '}
        <p className={style.text}>28. Ограничение ответственности</p>{' '}
        <p className={style.text}>
          28.1. ВЫ ПОНИМАЕТЕ И СОГЛАШАЕТЕСЬ С ТЕМ, ЧТО СЕРВИС, ЕГО КОМПОНЕНТЫ, ИНТЕРФЕЙСЫ, ЛЮБОЕ СВЯЗАННОЕ С НИМ
          ОБОРУДОВАНИЕ, ЛЮБАЯ ДОКУМЕНТАЦИЯ, ДАННЫЕ И ДРУГИЕ МАТЕРИАЛЫ И СУЩЕСТВУЮЩАЯ ТЕХНОЛОГИЯ ПРЕДОСТАВЛЯЮТСЯ ПО
          ПРИНЦИПУ «КАК ЕСТЬ» И «ПО МЕРЕ ДОСТУПНОСТИ» СО ВСЕМИ НЕДОСТАТКАМИ. ПРОВАЙДЕР НЕ ПРЕДОСТАВЛЯЕТ ВАМ КАКУЮ—ЛИБО
          ЯВНУЮ ИЛИ ПОДРАЗУМЕВАЕМУЮ ГАРАНТИЮ ПРИГОДНОСТИ ДЛЯ КОНКРЕТНОЙ ЦЕЛИ, ОТСУТСТВИЯ НАРУШЕНИЙ ИЛИ КАКОЙ—ЛИБО
          ГАРАНТИИ, КОТОРАЯ МОЖЕТ ВОЗНИКНУТЬ ИЗ СПОСОБА ОСУЩЕСТВЛЕНИЯ, СОВЕРШЕНИЯ ОПЕРАЦИЙ НА СЕРВИСЕ ИЛИ ОБЫЧАЕВ
          ДЕЛОВОГО ОБОРОТА.
        </p>
        <p className={style.text}>
          28.2. БЕЗ УЩЕРБА ДЛЯ ВЫШЕИЗЛОЖЕННОГО ПРОВАЙДЕР НЕ ДАЕТ НИКАКИХ ЗАВЕРЕНИЙ ИЛИ ГАРАНТИЙ В ОТНОШЕНИИ
          СВОЕВРЕМЕННОСТИ, ТОЧНОСТИ ИЛИ ПОЛНОТЫ ЛЮБЫХ ДАННЫХ ИЛИ ДРУГОЙ ИНФОРМАЦИИ НА СЕРВИСЕ ИЛИ В ОТНОШЕНИИ
          РЕЗУЛЬТАТОВ, КОТОРЫЕ ВЫ ПОЛУЧАЕТЕ ПРИ ДОСТУПЕ ИЛИ ИСПОЛЬЗОВАНИИ СЕРВИСА. ПРОВАЙДЕР НЕ НЕСЕТ КАКОЙ—ЛИБО
          ОТВЕТСТВЕННОСТИ ПО ПРЕТЕНЗИЯМ, СВЯЗАННЫМ С ЛЮБЫМ ПРОГРАММНЫМ ОБЕСПЕЧЕНИЕМ, ЛЮБОЙ ТЕХНОЛОГИЕЙ, ЛЮБЫМ
          ОБОРУДОВАНИЕМ, ДАННЫМИ ИЛИ ЛЮБОЙ ДРУГОЙ ИНФОРМАЦИЕЙ, МАТЕРИАЛАМИ, ВАЛЮТОЙ ИЛИ ТЕМ, ЧТО ДОСТУП К СЕРВИСУ
          СООТВЕТСТВУЕТ ВАШИМ ТРЕБОВАНИЯМ ИЛИ ЧТО ДОСТУП К НЕМУ ДОЛЖЕН БЫТЬ НЕПРЕРЫВНЫМ, СВОЕВРЕМЕННЫМ, БЕЗОПАСНЫМ,
          ПОЛНЫМ, ТОЧНЫМ, БЕЗ ОШИБОК И ДЕФЕКТОВ.
        </p>
        <p className={style.text}>
          28.3. ВЫ ПРИЗНАЕТЕ, ЧТО ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ И ОБОРУДОВАНИЕ, ИСПОЛЬЗУЕМОЕ ВАМИ, МОЖЕТ НЕ ПОДДЕРЖИВАТЬ
          НЕКОТОРЫЕ ФУНКЦИИ СЕРВИСА.
        </p>
        <p className={style.text}>
          28.4. Провайдер освобождается от ответственности за любые случайные, штрафные, специальные или косвенные
          убытки, за любую потерю прибыли, потерянные данные, потерю возможности использовать Сервис, операционный
          простой, потерю деловой репутации, затраты на замену сервисов, или затраты на простои, понесенные
          Пользователем в результате заключения или в связи с настоящим Соглашением, даже если Пользователь ранее был
          уведомлены о возможности возникновения такой потери.28.5. Совокупная ответственность Провайдера за любые
          убытки, понесенные Пользователем по любой причине, связанной с или вытекающей из настоящего Соглашения или
          использования Сервиса, ограничивается суммой Комиссии, уплаченной Провайдеру на протяжении 3 (трех) месяцев до
          наступления события, повлекшего за собой такую ответственность.28.6. Ничто в настоящем Соглашении не должно и
          не может толковаться как исключающее или ограничивающее ответственность любой из Сторон за:
        </p>
        <p className={style.text}>
          28.5 ВСЕ СЧЕТА ЕСЛИ ОНИ ПОД УПРАВЛЕНИЕМ АНАЛИТИКОВ КОМПАНИИ ЯВЛЯЮТСЯ ЗАСТРАХОВАННЫ - В СЛУЧАЕ ПОТЕРИ ДЕПОЗИТА
          ПО ВИНЕ АНАЛИТИКА КОМПАНИЯ ОБЯЗУЕТСЯ ВОЗМЕТСТИТЬ УЩЕРБ В ТЕЧЕНИИ 3 РАБОЧИХ ДНЕЙ (КЛИЕНТ КОРРЕКТИРУЕТ КАК И
          КУДА)
        </p>
        <p className={style.text}>
          Совершения мошенничества или преднамеренного введения в заблуждение; причинение телесных повреждений или
          смерти умышленно или по неосторожности; или другие действия, ответственность за которые не может быть
          исключена или ограничена в силу закона. КОМПАНИЯ ИМЕЕТ ПОЛНОЕ ПРАВО ОТКАЗАТЬ КЛИЕНТУ В ОБСУЛУЖИВАНИИ ЕГО СЧЕТА
        </p>
        <p className={style.text}>
          28.7. Пользователь соглашается защищать, обеспечивать непривлечение к ответственности и настоящим освобождаете
          Провайдера от любых убытков, ущерба, расходов, претензий, судебного разбирательства, штрафа, включая судебные
          издержки, понесенные Провайдером, которые являются прямым или косвенным следствием:
        </p>
        <p className={style.text}>
          использования Сервиса; неспособности полностью и своевременно выполнить любое из обязательств Пользователя по
          настоящему Соглашению, включая неисполнение обязательств по любой операции или Ордеру; несоответствия
          действительности предоставленных гарантий; использования Сервиса третьим лицом, независимо от того, было ли
          это сделано с ведома или ведома Пользователя; любого нарушения Пользователем любого закона или прав третьих
          лиц.
        </p>{' '}
        <p className={style.text}>
          28.8. В случае возникновения каких—либо споров по вопросам, предусмотренным в настоящем Соглашении, Стороны
          предпримут все возможные меры для их разрешения путем переговоров.
        </p>
        <p className={style.text}>29. Изменения Соглашения</p>{' '}
        <p className={style.text}>
          29.1. Провайдер вправе изменить условия Соглашения в любой момент по своему усмотрению в одностороннем порядке
          без дополнительного уведомления. Пользователь обязуется ознакамливаться с актуальной версией Соглашения при
          совершении каждой операции.
        </p>{' '}
        <p className={style.text}>30. Конфиденциальность</p>
        <p className={style.text}>
          30.1. Пользователь обязуется хранить в тайне и сохранять конфиденциальность любой информации, которая касается
          Провайдера или Сервиса, полученной Пользователем в связи с заключением настоящего Соглашения. Данные
          обязательства не распространяются на конфиденциальную информацию, которая:
        </p>
        <p className={style.text}>
          законно находилась в распоряжении Пользователя до заключения настоящего Соглашения; добровольно раскрыта
          Пользователю третьими лицами, если такие лица не нарушают никаких обязательств по конфиденциальности такой
          информации; добровольно обнародована Провайдером; или уже известна неограниченному кругу лиц.
        </p>
        <p className={style.text}>
          30.2. Провайдер не передаёт информацию о Пользователе третьим лицам, за исключением своих представителей,
          контрагентов и государственных органов в целях исполнения Соглашения, исполнения закона и реализации своих
          прав и законных интересов. Данное ограничение не действует в отношении публичной и обезличенной информации.
        </p>
        <p className={style.text}> 31. Заключительные положения</p>
        <p className={style.text}>
          31.1. Все уведомления, сообщения и документы относительно выполнения Сторонами обязательств, возникших из
          Соглашения, должны быть направлены и считаются полученными Сторонами в случае направления их по электронной
          почте с использованием адреса электронной почты, указанной Пользователем в Личном кабинете, и адреса
          электронной почты Провайдера forcecapital@mail.ru. При этом аналогичные условия действуют в отношении
          уведомлений, сообщений и документов, обмен которыми осуществляется через интерфейс Сервиса.
        </p>
        <p className={style.text}>
          31.2. Если одна из Сторон не реализовала принадлежащее ей право, вытекающее из настоящего Соглашения, такая
          задержка не считается отказом от соответствующего права и не препятствует его реализации в будущем.
        </p>
        <p className={style.text}>
          31.3. Все сообщения, которыми Пользователь обменивается с Провайдером и другими Пользователями, могут быть
          сохранены Провайдером и использоваться при разрешении спорных ситуаций с участием Пользователя или Провайдера.
        </p>
      </>
    )}
  </div>
);

LandingModalContent.propTypes = {
  content: PropTypes.string.isRequired,
};
