/* eslint-disable max-len */
import style from './PaymentConfirmation.module.scss';

export const PaymentConfirmation = () => {
  console.log('PaymentConfirmation');
  return (
    <div>
      <h2 className={style.heading}>Спасибо за ваш платеж!</h2>
      <p className={style.text}>{` Мы уже начали его обработку.
          Пожалуйста, ожидайте, поступление на счёт займет не более 3 минут.
          В случае дополнительных вопросов,
          не стесняйтесь обращаться к нашей службе поддержки.`}</p>
    </div>
  );
};
