import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import PaymentConfirmation from './PaymentConfirmation';
import { Preloader } from '../../../../../UI/Preloader/Preloader';
import PaymentConfirmation from './PaymentConfirmation';
import PaymentTabs from './PaymentTabs';
import PaymentVariants from './PaymentVariants';
import BtcPaymentVariant from './PaymentVariants/BtcPaymentVariant';
import SberPaymentVariant from './PaymentVariants/SberPaymentVariant';
import TronPaymentVariant from './PaymentVariants/TronPaymentVariant';
import UsdtPaymentVariant from './PaymentVariants/UsdtPaymentVariant';
import style from './Payments.module.scss';

export const Payments = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem('userID');

  const [isLoading, setIsLoading] = useState(false);
  const [paymentVariant, setPaymentVariant] = useState('default');
  const [currencyAmount, setCurrencyAmount] = useState('');
  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
  });

  const setPaymentVariantState = (variant, amount, currencySign) => {
    setPaymentVariant(variant);
    setCurrencyAmount(`${amount} ${currencySign}`);
    setActiveTab({ tab1: true, tab2: true, tab3: false });
  };

  const paymentConfirm = () => {
    if (!currencyAmount || !userId) return;
    setActiveTab({ tab1: true, tab2: true, tab3: true });
    setIsLoading(true);

    const body = {
      paymentMethod: paymentVariant,
      amount: currencyAmount,
    };

    fetch(`https://force-capital.glitch.me/payment/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(() => {
      setIsLoading(false);
      setPaymentVariant('paymentConfirm');
    });
  };

  const paymentComponentToRender = {
    sber: <SberPaymentVariant currencyAmount={currencyAmount} paymentConfirm={paymentConfirm} />,
    usdt: <UsdtPaymentVariant amount={currencyAmount} paymentConfirm={paymentConfirm} />,
    tron: <TronPaymentVariant amount={currencyAmount} paymentConfirm={paymentConfirm} />,
    btc: <BtcPaymentVariant amount={currencyAmount} paymentConfirm={paymentConfirm} />,
    paymentConfirm: <PaymentConfirmation />,
    default: <PaymentVariants setPaymentVariantState={setPaymentVariantState} />,
  };

  return (
    <>
      {isLoading && <Preloader color={'white'} />}
      <section className={style.paymentSection}>
        <div className={style.paymentWrapper}>
          <div className={style.paymentHeadingWrapper}>
            <h2 className={style.paymentSectionTitle}>Пополнение счёта</h2>
            <button className={style.backBtn} onClick={() => navigate('/application/accounts')}>
              Вернуться
            </button>
          </div>
          <div className={style.paymentContentWrapper}>
            <PaymentTabs activeTab={activeTab} />
            {paymentComponentToRender[paymentVariant]}
          </div>
        </div>
      </section>
    </>
  );
};
