import {
  USER_REGISTRATION,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_ERROR,
} from './userRegistrationActions.js';

const initialState = {
  status: '',
  error: '',
};

export const userRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_REGISTRATION:
      return {
        ...state,
        status: 'loading',
        error: '',
      };
    case USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        status: 'loaded',
        error: '',
      };
    case USER_REGISTRATION_ERROR:
      return {
        ...state,
        status: 'rejected',
        error: action.error,
      };

    default:
      return state;
  }
};
