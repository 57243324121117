import { URL_API } from '../../utils/api';

export const USER_REGISTRATION = 'USER_REGISTRATION';
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_ERROR = 'USER_REGISTRATION_ERROR';

export const userRegistration = () => ({
  type: USER_REGISTRATION,
});

export const userRegistrationSuccess = () => ({
  type: USER_REGISTRATION_SUCCESS,
});

export const userRegistrationError = (error) => ({
  type: USER_REGISTRATION_ERROR,
  error,
});

export const userRegistrationAsync =
  ({ firstName, lastName, login, password, phoneNumber, email, rub = 0, bit = 0 }) =>
    (dispatch) => {
      dispatch(userRegistration());

      fetch(`${URL_API}/createUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: firstName,
          lastName,
          login,
          password,
          phoneNumber,
          email,
          creationDate: new Date(),
          transactions: [],
          rub,
          bit,
        }),
      }).then(
        (response) => {
          if (response.ok) {
            dispatch(userRegistrationSuccess());
          } else {
            dispatch(userRegistrationError());
          }
        }
      );
    };
