import PropTypes from 'prop-types';
// import { useEffect } from 'react';
import style from './PaymentTabs.module.scss';
/* eslint-disable max-len */

export const PaymentTabs = ({ activeTab }) => {
  console.log(style);
  // useEffect(() => {
  //   openFirstTab();
  // }, []);

  return (
    <div className={style.wrapper}>
      <div className={style.tabsWrapper}>
        <div className={`${style.stageWrapper} ${activeTab.tab1 ? style.active : ''}`}>
          <div className={style.numbers}>1</div>
          <p className={style.text}>Способ оплаты</p>
          <div className={style.line} />
          <div className={style.overline} />
        </div>
        <div className={`${style.stageWrapper} ${activeTab.tab2 ? style.active : ''}`}>
          <div className={style.numbers}>2</div>
          <p className={style.text}>Подтверждение</p>
          <div className={style.line} />
          <div className={style.overline} />
        </div>
        <div className={`${style.stageWrapper} ${activeTab.tab3 ? style.active : ''}`}>
          <div className={style.numbers}>3</div>
          <p className={style.text}>Завершение</p>
        </div>
      </div>
    </div>
  );
};

PaymentTabs.propTypes = {
  activeTab: PropTypes.object.isRequired,
};
