/* eslint-disable max-len */
import style from './RegistrationSuccess.module.scss';
import { ReactComponent as Check } from '../../../../../../assets/svg/registrationSuccessCheckmark.svg';
import { PROJECT_NAME } from '../../../../../../utils/projectName';
import PuffLoader from 'react-spinners/PuffLoader';
import { useSelector } from 'react-redux';

export const RegistrationSuccess = () => {
  const userRegistration = useSelector((state) => state.userRegistration);

  return (
    <section className={style.registrationSuccess}>
      {userRegistration.status === 'loaded' ? (
        <div className={style.registrationSuccessWrapper}>
          <Check className={style.registrationSuccessCheck} />
          <div className={style.registrationSuccessTextWrapper}>
            <h2 className={style.registrationSuccessTitle}>
              {`Вы успешно зарегистрировались на платформе ${PROJECT_NAME}!`}
            </h2>
            <p className={style.registrationSuccessText}>
              <a href='/application' className={style.registrationSuccessLink}>
                Перейти в личный кабинет
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div className={style.registrationSuccessWrapper}>
          <h2 className={style.registrationSuccessTitle}>Создание нового пользователя...</h2>
          <p className={style.registrationSuccessText}>Пожалуйста, подождите</p>
          <div className={style.registrationSuccessLoaderWrapper}>
            <PuffLoader color='#00BFFF' size={100} />
          </div>
        </div>
      )}
    </section>
  );
};
