/* eslint-disable max-len */
import { useState } from 'react';
import LandingModalWindow from '../../../../../UI/LandingModalWindow';
import style from './PlatformLinks.module.scss';

export const PlatformLinks = () => {
  const [modalActive, setModalActive] = useState({
    customerAgreement: false,
    AMLPolicy: false,
  });

  const closeModal = () => {
    setModalActive({
      customerAgreement: false,
      AMLPolicy: false,
    });
  };

  return (
    <ul className={style.linksList}>
      <li className={style.linksItem}>
        <button
          className={style.linksBtn}
          onClick={() => {
            setModalActive({ ...modalActive, customerAgreement: true });
          }}>
          Клиентское соглашение
        </button>
        {modalActive.customerAgreement && <LandingModalWindow content={'customerAgreement'} closeModal={closeModal} />}
      </li>
      <li className={style.linksItem}>
        <button className={style.linksBtn} onClick={() => setModalActive({ ...modalActive, AMLPolicy: true })}>
          Политика AML
        </button>
        {modalActive.AMLPolicy && <LandingModalWindow content={'AMLPolicy'} closeModal={closeModal} />}
      </li>
      {/* <li className={style.linksItem}>
        <a className={style.link} href="#platformInfo">
          О компании
        </a>
      </li>
      <li className={style.linksItem}>
        <a className={style.link} href="#reviews">
          Отзывы
        </a>
      </li>
      <li className={style.linksItem}>
        <a className={style.link} href="#registration">
          Регистрация
        </a>
      </li>
      <li className={style.linksItem}>
        <a className={style.link} href="#profit">
          Почему с нами выгодно?
        </a>
      </li> */}
    </ul>
  );
};
