/* eslint-disable max-len */
import style from './LandingModalWindow.module.scss';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { LandingModalContent } from './LandingModalContent/LandingModalContent';
import { useRef, useEffect } from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useInView } from 'react-intersection-observer';

export const LandingModalWindow = ({ content, closeModal }) => {
  const modalRef = useRef();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useOutsideClick(modalRef, closeModal);
  useEffect(() => {
    document.documentElement.style.overflowY = 'hidden';
    return () => {
      document.documentElement.style.overflowY = 'auto';
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={style.modalOverlay}>
      <div className={`${style.modalContent} ${style.active}`} ref={modalRef}>
        <div className={style.handleWrapper}>
          <div className={style.modalContentBlurUpperline} />
          <button className={style.closeButton} type="button" onClick={() => closeModal()}>
            <span className={style.closeButtonLineOne} />
            <span className={style.closeButtonLineTwo} />
          </button>
          <div className={style.modalText}>
            <LandingModalContent content={content} />
            <div ref={ref} />
          </div>
        </div>
        {!inView && <div className={style.modalContentBlurUnderline} />}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

LandingModalWindow.propTypes = {
  content: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
};
