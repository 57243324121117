import {
  USER_WITHDRAW,
  USER_WITHDRAW_SUCCESS,
  USER_WITHDRAW_ERROR,
  USER_WITHDRAW_INITIAL,
} from './userWithdrawActions.js';

const initialState = {
  status: '',
  error: '',
};

export const userWithdrawReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_WITHDRAW_INITIAL:
      return {
        ...state,
        status: '',
        error: '',
      };
    case USER_WITHDRAW:
      return {
        ...state,
        status: 'loading',
        error: '',
      };
    case USER_WITHDRAW_SUCCESS:
      return {
        ...state,
        status: 'loaded',
        error: '',
      };
    case USER_WITHDRAW_ERROR:
      return {
        ...state,
        status: 'rejected',
        error: action.error,
      };

    default:
      return state;
  }
};
