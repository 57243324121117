/* eslint-disable max-len */
import style from './Registration.module.scss';
import { useEffect, useState } from 'react';
import FullNameForm from './FullNameForm';
import ContactsForm from './ContactsForm';
import AccountCreationForm from './AccountCreationForm';
import RegistrationSuccess from './RegistrationSuccess';
import RegistrationTabs from './RegistrationTabs';
import { useDispatch, useSelector } from 'react-redux';
import { userRegistrationAsync } from '../../../../../store/userRegistration/userRegistrationActions';

export const Registration = () => {
  const usersRegistration = useSelector((state) => state.userRegistration);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState({
    firstTab: true,
    secondTab: false,
    thirdTab: false,
  });

  const [isTabValid, setIsTabValid] = useState({
    firstTabValid: false,
    secondTabIsValid: false,
    thirdTabIsValid: false,
  });

  const [formValues, setFormValues] = useState({
    name: '',
    surname: '',
    phoneNumber: '',
    eMail: '',
    login: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    if (usersRegistration.status === 'rejected') {
      setIsTabValid({ ...isTabValid, thirdTabIsValid: false });
    }
  }, [usersRegistration.status]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phoneNumber') {
      const regex = /[^0-9|+]/g;
      const phoneValue = value.replace(regex, '');

      return setFormValues({ ...formValues, phoneNumber: phoneValue });
    }

    if (name === 'login' || name === 'password' || name === 'confirmPassword') {
      const regex = /[^a-zA-Z0-9_]/g;
      const editedValue = value.replace(regex, '');

      return setFormValues({ ...formValues, [name]: editedValue });
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const fullNameFormSubmit = () => {
    setIsTabValid({ ...isTabValid, firstTabValid: true });

    setActiveTab(() => ({
      firstTab: false,
      secondTab: true,
      thirdTab: false,
    }));
  };

  const contactsFormSubmit = () => {
    setIsTabValid({ ...isTabValid, secondTabIsValid: true });

    setActiveTab(() => ({
      firstTab: false,
      secondTab: false,
      thirdTab: true,
    }));
  };

  const accountCreationFormSubmit = () => {
    dispatch(
      userRegistrationAsync({
        firstName: formValues.name,
        lastName: formValues.surname,
        login: formValues.login,
        password: formValues.password,
        phoneNumber: formValues.phoneNumber,
        email: formValues.eMail,
      })
    );

    setIsTabValid({ ...isTabValid, thirdTabIsValid: true });
  };

  const switchActiveTab = (tab) => {
    const copyActiveTabObj = { ...activeTab };

    Object.keys(copyActiveTabObj).forEach((item) => {
      item === tab ? (copyActiveTabObj[tab] = true) : (copyActiveTabObj[item] = false);
    });

    setActiveTab(() => copyActiveTabObj);
  };

  return (
    <section className={style.registrationSection} id='registration'>
      <div className={style.registrationWrapper}>
        <h2 className={style.registrationTitle}>Как зарегистрироваться на платформе</h2>
        {isTabValid.firstTabValid & isTabValid.secondTabIsValid & isTabValid.thirdTabIsValid ? (
          <RegistrationSuccess />
        ) : (
          <>
            <p className={style.registrationText}>Пошагово заполните все поля формы</p>
            <div className={style.registrationFormUnderlay}>
              <div className={style.registarionFormWrapper}>
                <RegistrationTabs
                  switchActiveTab={switchActiveTab}
                  activeTab={activeTab}
                  isTabValid={isTabValid}
                />
                {activeTab.firstTab && (
                  <FullNameForm
                    fullNameFormSubmit={fullNameFormSubmit}
                    formValues={formValues}
                    handleChange={handleChange}
                  />
                )}
                {activeTab.secondTab && (
                  <ContactsForm
                    contactsFormSubmit={contactsFormSubmit}
                    formValues={formValues}
                    handleChange={handleChange}
                  />
                )}
                {activeTab.thirdTab && (
                  <AccountCreationForm
                    accountCreationFormSubmit={accountCreationFormSubmit}
                    formValues={formValues}
                    handleChange={handleChange}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
