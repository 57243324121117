/* eslint-disable indent */
import { URL_API } from '../../utils/api';

export const USER_WITHDRAW = 'USER_WITHDRAW';
export const USER_WITHDRAW_SUCCESS = 'USER_WITHDRAW_SUCCESS';
export const USER_WITHDRAW_ERROR = 'USER_WITHDRAW_ERROR';
export const USER_WITHDRAW_INITIAL = 'USER_WITHDRAW_INITIAL';

export const userWithdrawInitial = () => ({
  type: USER_WITHDRAW_INITIAL,
});

export const userWithdraw = () => ({
  type: USER_WITHDRAW,
});

export const userWithdrawSuccess = () => ({
  type: USER_WITHDRAW_SUCCESS,
});

export const userWithdrawError = () => ({
  type: USER_WITHDRAW_ERROR,
});

export const userWithdrawRequestAsync =
  ({ id, rub, bit, transactions, userCard, rubAmount }) =>
  (dispatch) => {
    dispatch(userWithdraw());

    fetch(`${URL_API}/userWithdraw/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rub,
        bit,
        transactions,
        userCard,
        rubAmount,
      }),
    }).then((response) => {
      if (response.ok) {
        return dispatch(userWithdrawSuccess());
      } else {
        dispatch(userWithdrawError());
      }
    });
  };
