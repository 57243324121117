import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import style from './UsdtPaymentVariant.module.scss';

export const UsdtPaymentVariant = ({ amount, paymentConfirm }) => {
  const navigate = useNavigate();

  const backToAccount = () => {
    navigate('/application/accounts');
  };

  const onClickConfirm = () => {
    paymentConfirm();
  };

  const onClickHandler = () => {
    navigator.clipboard.writeText('TKPcoNfAwhg1NhaLgZjnUoge7vG8wzHtCQ');
  };

  return (
    <section className={style.contentWrapper}>
      <div>
        <h2 className={style.heading}>Кошелёк для оплаты USDT:</h2>
        <p className={style.wallet} onClick={onClickHandler}>TKPcoNfAwhg1NhaLgZjnUoge7vG8wzHtCQ</p>
        <p className={style.subtitle}>{`К переводу: ${amount}`}</p>
      </div>
      <div className={style.paymentAgreementWrapper}>
        <button className={style.paymentAgreementBtn} onClick={backToAccount}>
          Отмена
        </button>
        <button className={style.paymentAgreementBtn} onClick={onClickConfirm}>
          Перевод выполнен
        </button>
      </div>
    </section>
  );
};

UsdtPaymentVariant.propTypes = {
  amount: PropTypes.string,
  paymentConfirm: PropTypes.func,
};
