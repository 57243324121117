/* eslint-disable max-len */
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ModalWindow } from '../../../../../../UI/ModalWindow/ModalWindow';
import { ReactComponent as BtcLogo } from '../../../../../../assets/svg/btcLogo.svg';
import { ReactComponent as SberLogo } from '../../../../../../assets/svg/sberLogo.svg';
import { ReactComponent as TronLogo } from '../../../../../../assets/svg/tronLogo.svg';
import { ReactComponent as UsdtLogo } from '../../../../../../assets/svg/usdtLogo.svg';
import style from './PaymentVariants.module.scss';

export const PaymentVariants = ({ setPaymentVariantState }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentMethodMessage, setPaymentMethodMessage] = useState('');
  const [currencySign, setCurrencySign] = useState('');

  // const navigate = useNavigate();
  const onClickSber = () => {
    setPaymentMethod('sber');
    setPaymentMethodMessage('На какую сумму вы хотите пополнить свой счёт?');
    setCurrencySign('₽');
    setIsModalOpen(true);
    // navigate('/application/payments/info/sber');
  };

  const onClickUSDT = () => {
    setPaymentMethod('usdt');
    setPaymentMethodMessage('Сколько вы хотите перевести USDT Tether TRC20?');
    setCurrencySign('USDT');
    setIsModalOpen(true);
    // navigate('/application/payments/info/usdt');
  };

  const onClickTron = () => {
    setPaymentMethod('tron');
    setPaymentMethodMessage('Сколько вы хотите перевести TRX?');
    setCurrencySign('TRX');
    setIsModalOpen(true);
    // navigate('/application/payments/info/tron');
  };

  const onClickBTC = () => {
    setPaymentMethod('btc');
    setPaymentMethodMessage('Сколько вы хотите перевести BTC?');
    setCurrencySign('₿');
    setIsModalOpen(true);
    // navigate('/application/payments/info/btc');
  };

  const handleChange = event => {
    const { value } = event.target;
    const keyPressed = event.nativeEvent.data;
    const regexp = /[^0-9.]/g;

    if (!regexp.test(keyPressed) || keyPressed === null) {
      const cleanedValue = value.replace(regexp, '');
      if (inputValue.toString().includes('.') && keyPressed === '.') return;
      setInputValue(cleanedValue);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setInputValue('');
  };

  const modalConfirmAction = () => {
    setPaymentVariantState(paymentMethod, inputValue, currencySign);
    setIsModalOpen(false);
    setInputValue('');
  };

  return (
    <section className={style.paymentSection}>
      {isModalOpen && (
        <ModalWindow
          content={
            <div className={style.modalContentWrapper}>
              <h2 className={style.modalContentHeading}>{paymentMethodMessage}</h2>
              <div className={style.modalContentInputWrapper} data-content={currencySign}>
                <input
                  type='text'
                  inputMode='decimal'
                  className={style.modalContentInput}
                  value={inputValue}
                  onChange={handleChange}
                />
              </div>
            </div>
          }
          modalConfirmAction={modalConfirmAction}
          closeModal={closeModal}
        />
      )}
      <h2 className={style.paymentHeading}>Выберите способ оплаты</h2>
      <div className={style.paymentWrapper}>
        <button className={style.paymentVariant} onClick={onClickSber}>
          <SberLogo className={style.paymentVariantLogo} />
          <span className={style.paymentVariantTitle}>По реквизитам</span>
        </button>
        <button className={style.paymentVariant} onClick={onClickUSDT}>
          <UsdtLogo className={style.paymentVariantLogo} />
          <span className={style.paymentVariantTitle}>
            USDT <span>Tether TRC20</span>
          </span>
        </button>
        <button className={style.paymentVariant} onClick={onClickTron}>
          <TronLogo className={style.paymentVariantLogo} />
          <span className={style.paymentVariantTitle}>TRX</span>
        </button>
        <button className={style.paymentVariant} onClick={onClickBTC}>
          <BtcLogo className={style.paymentVariantLogo} />
          <span className={style.paymentVariantTitle}>BTC</span>
        </button>
      </div>
    </section>
  );
};

PaymentVariants.propTypes = {
  setPaymentVariantState: PropTypes.func.isRequired,
};
